import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/home',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ './views/home/Index.vue'),
            meta: {
                title: 'HOME'
            }
        },
        {
            path: '/launchpad',
            name: 'launchpad',
            component: () => import(/* webpackChunkName: "launchpad" */ './views/launchpad/Index.vue'),
            meta: {
                title: 'LAUNCHPAD'
            }
        },
        {
            path: '/collections',
            name: 'collections',
            component: () => import(/* webpackChunkName: "collections" */ './views/collections/Index.vue'),
            meta: {
                title: 'COLLECTIONS'
            }
        },
        {
            path: '/myProfile',
            name: 'myProfile',
            component: () => import(/* webpackChunkName: "myProfile" */ './views/myProfile/Index.vue'),
            meta: {
                title: 'MYPROFILE'
            }
        },
        {
            path: '/bridge',
            name: 'bridge',
            component: () => import(/* webpackChunkName: "bridge" */ './views/bridge/Index.vue'),
            meta: {
                title: 'BRIDGE'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({
            path: '/launchpad',
        });
    } else {
        next();
    }
});

const VueRouterPush = Router.prototype.push;

Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
}

export default router;
