import Vue from 'vue';
import Vuex from 'vuex';
import lang_zh from './tools/lang_zh.js';
import lang_en from './tools/lang_en.js';
import transfer from "./tools/transfer";

Vue.use(Vuex);

const initState = {
    lang: {cn: lang_zh, en: lang_en},
    languageType: 'en',
    walletType: 0, // 0: OKX, 1: MetaMask, 2: Gate
    accounts: [],
    connectWalletShow: false,
    toastShow: false,
    toastShowType: 0,
    transactionHash: '',
    networkIDstring: 0,
    appKey: null
};

let state = {
    ...initState
};

const mutations = {
    /*
     * state
     */
    setState(state, params) {
        for (let k in params) {
            state[k] = params[k];
        }
    }
};

const actions = {
    logOut() {
        for (let k in state) {
            if (k === 'connectWalletShow') continue;
            if (k === 'networkIDstring') continue;
            state[k] = initState[k];
        }
        localStorage.removeItem('accounts');

        transfer.$emit("closeMyProfile");
    }
};

export default new Vuex.Store({
    state,
    mutations,
    actions
})
